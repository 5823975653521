.container {

    background-color: #282a36;

    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
}

.content {
    width: 399px;
    height: 844px;
    background-color: #ffffff;
    /*color: #ffffff;*/
    position: relative;
}