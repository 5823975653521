.container {
    min-height: 100%;
}

.statusImg {
    width: 12%;
    filter: invert(99%) sepia(90%) saturate(2%) hue-rotate(154deg) brightness(110%) contrast(100%);
}

.noteBox {
    background-color: var(--lightBlue);
    border: 2px solid var(--lightBlue);
}

.statusBox {
    padding: 1vh 1vw
}

.Blocked {
    background-color: var(--blackRed);
    border: 2px solid var(--darkRed);
    color: var(--white);
}

.Rejected {
    background-color: var(--blackRed);
    border: 2px solid var(--darkRed);
    color: var(--white);
}

.Pending {
    background-color: var(--orange);
    border: 2px solid var(--blackOrange);
}

.Approved {
    background-color: var(--blackGreen);
    border: 2px solid var(--darkGreen);
    color: var(--white);
}

.thisButton {
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.userFiles {
    width: 100% !important;
}

.wrapper {
    background-color: rgba(0, 0, 0, 0.6);
    height: 17%;
    bottom: 0px;
    border-radius: 8px 8px 0px 0px;
    /*filter: blur(4px);*/
    z-index: 2;
}

.title {
    margin-right: 0.5vw;
}

.ltr .title {
    margin-right: initial;
    margin-left: 0.5vw;
}

.nav {
    border-right: 2px dotted var(--gray);
    margin-right: 2.8%;
    padding-right: 2vw;
}

.ltr .nav {
    margin-right: initial;
    padding-right: initial;
    margin-left: 2.8%;
    padding-left: 2vw;
}

.imageWrapper {
    height: 32vh;
    background-color: var(--gray);
    box-shadow: 0 9px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);


}

.blankImage {
    width: 45%;
}

.image {
    box-shadow: 0 9px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);
}

@media (max-width: 480px) {
    .nav {
        padding-right: 7vw;
    }

    .ltr .nav {
        padding-right: initial;
        padding-left: 7vw;
    }

    .title {
        margin-right: 2vw;
    }

    .ltr .title {
        margin-right: initial;
        margin-left: 2vw;
    }

    .statusBox {
        padding: 1vh 4vw
    }
}