.container {
    height: 100vh;
    /*background-color: var(--blue);*/
}

.logo {
    width: 7%;
}


.version {

    bottom: 15%;

}

@media (max-width: 480px) {

    .logo {
        width: 25%;
    }


}