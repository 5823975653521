.container {
    /* border: 1px solid #E0E0E0;*/
}

.amountBox {
    background-color: var(--lightBlue);
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;

}

.icon {
    top: 15%;
    left: 7%;
}