.container {
    background-color: var(--blue);
}

.loading {
    height: 3.5vh;
    background-color: var(--cardBodyAlpha);
}

.hide {
    filter: blur(4px);
}

.name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}