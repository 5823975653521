.container {
    border: 0.8px solid #E0E0E0;
    padding: 1.5vh 1vw;
}

.sampleImg {
    width: 95%;
}

@media (max-width: 480px) {
    .container {
        border: 0.8px solid #E0E0E0;
        padding: 1.5vh 4vw;
    }

    .sampleImg {
        width: 80%;
    }
}

