.container {
    border: 1px solid #E0E0E0;
}

.header {
    background-color: var(--lightBlue);
    border-radius: 8px 8px 0 0;
}


.circle {
    width: 3.3vw;
    height: 3.3vw;
    border-radius: 100%;
    background-color: var(--cardHeader);
}

.rectangle {
    height: 5.85vh;
    background-color: var(--cardHeader);
}


@media (max-width: 480px) {
    .circle {
        width: 14vw;
        height: 14vw;
        border-radius: 100%;
        background-color: var(--cardHeader);
    }

    .rectangle {
        height: 6.5vh;
        background-color: var(--cardHeader);
    }
}