.container {
    border: 1px solid #E0E0E0;
    background: var(--white);
    top: 47%;
    right: 7%;
    padding: 3vh 1vw 2vh;
}


.box {
    width: 5.7vw;
    height: 5.7vw;
    background-color: var(--lightBlue);
}

/*.first {
    margin-left: 1vw
}

.ltr .first {
    margin-right: 1vw;
    margin-left: initial;
}*/


@media screen and (min-width: 480px) and (max-width: 992px) {

    .container {
        top: 55%;
        padding: 2vh 2.5vw 1vh;
    }
    .box {
        width: 10.5vw;
        height: 10.5vw;
        background-color: var(--lightBlue);
    }
}


@media (max-width: 480px) {

    .container {
        top: 54%;
        padding: 3vh 5vw 2vh;
    }
    .box {
        width: 22.5vw;
        height: 22.5vw;
        background-color: var(--lightBlue);
    }
}

.box.disabled {
    filter: grayscale(1);
    color: var(--gray);
}