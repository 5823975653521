.active {
    background-color: var(--blue);
    color: var(--white);
    border: 1px solid var(--blue);

}

.container {
    /*border: 1px solid #E0E0E0;*/
    background: var(--white);
}

.item.disabled {
    filter: grayscale(1);
}
.item {
    border: 1px solid #E0E0E0;
}
.container div:first-child {
    border-radius: 0 100px 100px 0;
}
.container.two div:nth-child(2n) {
    border-radius: 100px 0 0 100px;
}
.container div:nth-child(3n) {
    border-radius: 100px 0 0 100px;
}


/*.item:nth-child(2n) {
    border-radius: 100px 0 0 100px !important;
}
.item .third :nth-child(3n) {
    border-radius: 100px 0 0 100px !important;
}
.item:last-child {
    border-radius: 100px 0 0 100px !important;
}*/

