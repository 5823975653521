.container {

    /*background-color: #18a979;*/
}

.logo {
    width: 5vw;
    height: 5vw;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}


@media (max-width: 480px) {
    .logo {
        width: 20vw;
        height: 20vw;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;

}