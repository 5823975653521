.content {
   /* background-color: var(--lightBlueAlpha);*/

    max-width: 75%;
    word-break: break-word;
}

.container {
    /*background-color: #EFBE0F;*/

}