.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

.image {
    width: 15%;
}

.loading {
    height: 9vh;
    background-color: var(--cardHeader);
}