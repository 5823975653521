.inputGroup {
   /* margin: 3vh 0 6vh;*/
    height: 6vh;
    display: flex;
    align-items: center;
    position: relative;
}

.inputGroup .input:focus {
    border: 1px solid var(--black) !important;
}

.inputGroup.hasError .input{
    border-color: var(--red) !important;
}


.inputGroup label {
    position: absolute;
    right: 6%;
    /* top: 0vh; */
    direction: ltr;
    font-size: 1.1rem;
    background-color: #fff;
    padding: 0 4.5%;
    transition: all 0.5s;
    text-align: center;
    z-index: 1;
}

/*@media (max-width: 480px) {
    .inputGroup label {
        right: 3%;
        padding: 0 2vw;
    }

}*/



.thisIcon{
    position: absolute;
    left: 2.5%;
}


.inputGroup .input {
    width: 100%;
    height: 6vh;
    border: 0.8px solid #E0E0E0;
    border-radius: 100px;
    font-style: normal;
    font-family: iranyekan, serif;
    /* padding: 10px 15px; */
    /* margin: 1vh auto 1vh; */
    position: relative;
    text-align: center;
    direction: ltr;
    display: block;
    font-size: 1rem;
}

.inputGroup textarea {
    width: 100%;
    padding: 1.2vh 2.5vw;

    box-sizing: border-box;
    resize: none;
    /*min-height: 6vh;*/
    border: 0.8px solid #E0E0E0;
    border-radius: 100px;
    font-style: normal;
    font-family: iranyekan, serif;
    /* padding: 10px 15px; */
    /* margin: 1vh auto 1vh; */
    position: relative;
    text-align: start;
    direction: rtl;
    display: block;
}
.inputGroup.show-error .input:focus {
    border-color: var(--red) !important;
}
.inputGroup.show-error .input:focus + label {
    color: var(--red);
}
.inputGroup.show-error label {
    color: var(--red);
}
.inputGroup.show-error .input {
    border-color: var(--red);
}
.inputGroup.show-error .error-box img, .inputGroup.show-error .error-box p {
    display: block;
}
.inputGroup.show-error.password-input .pass-icon {
    display: none;
}
.inputGroup .error-box img {
    width: 5vw;
    top: 3.68vh;
    left: 15.25vw;
    display: none;
    position: absolute;
    filter: invert(49%) sepia(67%) saturate(6415%) hue-rotate(342deg) brightness(98%) contrast(94%);
}
.inputGroup .error-box p {
    display: none;
    text-align: right;
    padding: 0.5vh 15vw;
    margin-bottom: -0.75vh;
    filter: invert(49%) sepia(67%) saturate(6415%) hue-rotate(342deg) brightness(98%) contrast(94%);
}
.inputGroup .input:focus + label {
    /*left: 3.5vw;
    top: 0.2vh;
    font-size: 1.25vw;
    z-index: 1;*/
    /*left: 8vw;*/
    top: -30%;
    font-size: 0.9rem;
    z-index: 1;
    transition: top ease 5s;
}
.inputGroup .hasValue {
    /*left: 8vw !important;*/
    top: -30% !important;
    font-size: 1rem !important;
    padding: 0 3.7%;
    /*left: 3.5vw !important;
    top: 0.2vh !important;
    font-size: 1.25vw !important;*/
}


.select {
    left: 7%;
}

.icon {
    position: absolute;
    left: 3.5%;
    z-index: 2;
}


.inputGroup :global(.rmdp-container ) {
    width: 100%;
    height: 100%;

}

.inputGroup :global(.b-date ) {
    font-family: iranyekan, serif !important;
}
:global(.ltr ) .inputGroup :global(.b-date ) {
    font-family: 'FractulAlt','Mulish', sans-serif !important;
}

/*.inputGroup :global(.rmdp-wrapper ) {

    width: 95% !important;
}
@media (max-width: 480px) {
    .inputGroup :global(.rmdp-wrapper ) {
        width: max-content !important;
    }
}*/



.inputGroup :global(.bg-dark.rmdp-wrapper) {
    background-color: var(--cardBody) !important;
    color: var(--textColor) !important;
}

.inputGroup :global(.rmdp-shadow ) {
    box-shadow: 0 0 5px var(--cardHeader);
    border: 1.5px solid var(--cardBorder);
}
.inputGroup :global(.rmdp-ep-shadow:after ) {
    box-shadow: 0 0 5px var(--cardHeader);
    border: 1.5px solid var(--cardBorder);
}

.inputGroup :global(.rmdp-panel-body li .b-date) {
    padding: 0;
}
.inputGroup :global(.rmdp-panel-body li) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;;
    padding: 1vh 2.5vw;
    margin: 1.25vh 0.3vw;
}

.inputGroup :global(.rmdp-container input ) {
    /*border: none;*/
    text-align: center;
    background: none;
    color: var(--textColor) !important;
}

.datePicker {
    width: 100%;
    height: 100%;
    direction: rtl !important;
}