

.container {

    background-color: var(--white);




}



.link{
    border-bottom: 0.5px dotted rgba(103, 103, 103, 0.4);
}
.link:first-child{
    border-top: 0.5px dotted rgba(103, 103, 103, 0.4);
}




.logo {
    width: 9%;
}

.loading {
    height: 4vh;
    background-color: var(--cardHeader);
}