/*.container {
    top: 0;
    bottom: 0;
    width: 100%;
    position: absolute;
    overflow: hidden;
}*/



.container {
    background-color: #fff;
    top: 3.5%;
    bottom: 3.5%;
    left: 31%;
    right: 31%;
    position: absolute;
    overflow: hidden;
}

.content {
    width: 67%;
    border-radius: 8px;
    border: 1px solid var(--gray);
}

@media screen and (min-width: 480px) and (max-width: 992px) {
    .container {
        background-color: #fff;
        top: 8%;
        bottom: 8%;
        left: 24%;
        right: 24%;
        position: absolute;
        overflow: hidden;
        border: 1px solid var(--gray);
        border-radius: 8px;
    }
    .content {
        width: 100%;
        border-radius: 0;
        border: none;
    }
}

@media (max-width: 480px) {
    .container {
        background-color: inherit;
        top: 0;
        bottom: 0;
        width: 100%;
        left: inherit;
        right: inherit;
        border: inherit;
        border-radius: inherit;
        position: absolute;
        overflow: hidden;
    }

    .content {
        width: 100%;
        border-radius: 0;
        border: none;
    }
}