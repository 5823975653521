.switch {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: 1.5rem;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 34px;
    background-color: var(--menu);
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    width: 1.1rem;
    height: 1.1rem;
    top: 0.2rem;
    left: 0.18rem;
    right: initial;
    background-color: var(--white);
}
:global(.ltr) .slider:before {
    right: 0.18rem;
    left: initial;
}
input:checked + .slider {
    background-color: var(--activeTab);
}
input:focus + .slider {
    box-shadow: 0 0 1px var(--activeTab);
}
input:checked + .slider:before {
    right: 0.18rem;
    left: initial;
}
:global(.ltr) input:checked + .slider:before {
    left: 0.18rem;
    right: initial;
}
.slider:before {
    border-radius: 50%;
}