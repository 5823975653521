.thisInput :global(textarea){
    border-radius: 4px !important;

}

.container {
    background-color: #42f6f6;
    position: fixed;
    bottom: 0;

}

/*
.thisInput :global(input){
    position: fixed;
    bottom: 0;

}*/


