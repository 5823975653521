.container {
    min-height: 100%;
}

.noteBox {
    background-color: var(--lightBlue);
}


.loading {
    height: 5.2vh;
    background-color: var(--cardHeader);
    border-radius: 100px;
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;
}

