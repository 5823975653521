.container {
    background-color: #fff;
    height: 100%;
    margin-left: 2%;

    border: 1px solid var(--gray);
    border-radius: 8px;
}


.link{
    border-bottom: 0.5px dotted rgba(103, 103, 103, 0.4);
}
.link:first-child{
    border-top: 0.5px dotted rgba(103, 103, 103, 0.4);
}




.brandLogo {
    width: 45%;
}
.logo {
    width: 9%;
}

.loading {
    height: 4vh;
    background-color: var(--cardHeader);
}