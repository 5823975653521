.rectangle {
    height: 15vh;
    background-color: var(--cardHeader);
}


.rectangle2 {
    height: 5.85vh;
    background-color: var(--cardHeader);
}

.address {
    line-break: anywhere;
}


.amountBox {
    background-color: var(--lightBlue);
}


@media (max-width: 480px) {


    .rectangle {
        height: 20vh;
        background-color: var(--cardHeader);
    }

    .rectangle2 {
        height: 6.5vh;
        background-color: var(--cardHeader);
    }
}