.container {
    height: 100vh;
    /*background-color: #007bff;*/
}

.logo {
    width: 7%;
}

.thisButton {
    /*border: 1px solid var(--dyellow);*/
    background-color: var(--blue);
    color: var(--white);
    border-radius: 100px !important;

    width: 10%;

}

@media (max-width: 480px) {

    .logo {
        width: 25%;
    }

    .thisButton {
        width: 35%;

    }


}