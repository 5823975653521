.thisInput :global(textarea){
    border-radius: 4px !important;
}

.container {

}


.icon {
    width: 3vw;
    height: 3vw;
}

.Input {
    width: 100%;
    min-height: 6vh;
    max-height: 16vh;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 0.8px solid #E0E0E0;
}
.image {
    /*width: 100%;*/
    height: 40vh;
    /*overflow-x: hidden;
    overflow-y: scroll;*/
    /*border: 0.8px solid #E0E0E0;*/
    background-repeat: no-repeat;
    /*background-position: start start;*/
    background-size: contain;
    background-color: transparent;
    box-shadow: 0 9px 16px rgba(159,162,191,.18), 0 2px 2px rgba(159,162,191,.32);

}
.cancel {
    top: 1.5vh;
    left: 0.30vw;
}

.sendingIcon {
    width: 70%;
}

@media screen and (min-width: 480px) and (max-width: 992px) {
    .icon {
        width: 5.8vw;
        height: 5.8vw;
    }
    .cancel {
        top: 1.25vh;
        left: 1vw;
    }
}



@media (max-width: 480px) {
    .icon {
        width: 12vw;
        height: 12vw;
    }
    .cancel {
        top: 1.25vh;
        left: 1vw;
    }
}
