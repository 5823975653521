html, body {
    font-size: 0.75vw;
    line-height: 4vh;

}

.button {
    height: 5.65vh;
    font-size: 1rem;
}
input {
    font-size: 1rem;
}

.go2072408551 {
    max-width: 30% !important;
}

@media screen and (min-width: 480px) and (max-width: 992px) {
    html, body {
        font-size: 1.3vw;
        line-height: 3.4vh;
        background-color: inherit;
    }

    .button {
        height: 5.65vh;
    }

    .go2072408551 {
        max-width: 45% !important;
    }
}

@media (max-width: 480px) {

    html, body {
        font-size: 2.9vw;
        line-height: 3.6vh;

        background-color: inherit;

    }

    .button {
        height: 5.65vh;
    }

    .go2072408551 {
        max-width: inherit !important;
    }

}